import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

//components
const Header = lazy(()=>import('./components/header'));
const Footer = lazy(()=>import('./components/footer'));
const Redes = lazy(()=>import('./components/redes'));
const Loading = lazy(()=>import('./components/loading'));
// pages
const Home = lazy(() => import('./pages/home'));
const About = lazy(()=>import('./pages/about'));
const Contact = lazy(()=>import('./pages/contact'));
const Sportsbook = lazy(()=>import('./pages/sportsbook'));
const Online_Casino = lazy(()=>import('./pages/online_casino'));
const Racing = lazy(()=>import('./pages/racing'));
const Live_Betting = lazy(()=>import('./pages/live_betting'));
const Live_Casino = lazy(()=>import('./pages/live_casino'));
const Bonus_Promos = lazy(()=>import('./pages/bonus_promos'));
const Banking = lazy(()=>import('./pages/banking'));
const Register = lazy(()=>import('./pages/register'));
//legal
const Anti_Money_Laundering = lazy(()=>import('./legal/anti_money_laundering'));
const Dispute_Resolution = lazy(()=>import('./legal/dispute_resolution'));
const Fairness_Rng = lazy(()=>import('./legal/fairness_rng'));
const Kyc_Policies = lazy(()=>import('./legal/kyc_policies'));
const Privacy_Personal_Data = lazy(()=>import('./legal/privacy_personal_data'));
const Responsible_Gaming = lazy(()=>import('./legal/responsible_gaming'));
const Rules_Regulations = lazy(()=>import('./legal/rules_regulations'));
const Self_Exclusion = lazy(()=>import('./legal/self_exclusion'));
const Terms_Service = lazy(()=>import('./legal/terms_service'));
const Account_Payouts_Bonuses = lazy(()=>import('./legal/account_payouts_bonuses'));

const Anti_Money_Laundering_esp = lazy(()=>import('./legal_esp/anti_money_laundering_esp.js'));
const Account_Payouts_Bonuses_esp = lazy(()=>import('./legal_esp/account_payouts_bonuses_esp.js'));
const Dispute_Resolution_esp = lazy(()=>import('./legal_esp/dispute_resolution_esp.js'));
const Fairness_Rng_esp = lazy(()=>import('./legal_esp/fairness_rng_esp.js'));
const Kyc_Policies_esp = lazy(()=>import('./legal_esp/kyc_policies_esp.js'));
const Privacy_Personal_Data_esp = lazy(()=>import('./legal_esp/privacy_personal_data_esp.js'));
const Responsible_Gaming_esp = lazy(()=>import('./legal_esp/responsible_gaming_esp.js'));
const Self_Exclusion_esp = lazy(()=>import('./legal_esp/self_exclusion_esp.js'));
const Terms_Service_esp = lazy(()=>import('./legal_esp/terms_service_esp.js'));



function App() {
  return (
    <div className="App font_popins">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
            <Header/>
            <Redes/>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="inicio" element={<Home />} />
              <Route path="apuestas-deportivas" element={<Sportsbook />} />
              <Route path="casino-linea" element={<Online_Casino />} />
              <Route path="apuestas-vivo" element={<Live_Betting />} />
              <Route path="casino-vivo" element={<Live_Casino />} />
              <Route path="carreras" element={<Racing />} />
              <Route path="nosotros" element={<About />} />
              <Route path="bonos" element={<Bonus_Promos />} />
              <Route path="contact" element={<Contact />} />
              <Route path="bankin" element={<Banking />} />
              <Route path="register" element={<Register />} />
              
              <Route path="anti_money_laundering" element={<Anti_Money_Laundering />} />
              <Route path="dispute_resolution" element={<Dispute_Resolution />} />
              <Route path="fairness_rng" element={<Fairness_Rng />} />
              <Route path="kyc_policies" element={<Kyc_Policies />} />
              <Route path="privacy_personal_data" element={<Privacy_Personal_Data />} />
              <Route path="responsible_gaming" element={<Responsible_Gaming />} />
              <Route path="rules_regulations" element={<Rules_Regulations />} />
              <Route path="self_exclusion" element={<Self_Exclusion />} />
              <Route path="terms_service" element={<Terms_Service />} />
              <Route path="account_payouts_bonuses" element={<Account_Payouts_Bonuses />} />
              
              <Route path="terms_service_esp" element={<Terms_Service_esp />} />
              <Route path="responsible_gaming_esp" element={<Responsible_Gaming_esp />} />
              <Route path="self_exclusion_esp" element={<Self_Exclusion_esp />} />
              <Route path="dispute_resolution_esp" element={<Dispute_Resolution_esp />} />
              <Route path="fairness_rng_esp" element={<Fairness_Rng_esp />} />
              <Route path="anti_money_laundering_esp" element={<Anti_Money_Laundering_esp />} />
              <Route path="kyc_policies_esp" element={<Kyc_Policies_esp />} />
              <Route path="privacy_personal_data_esp" element={<Privacy_Personal_Data_esp />} />
              <Route path="account_payouts_bonuses_esp" element={<Account_Payouts_Bonuses_esp />} />
            </Routes>
            <Footer/>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
